var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("product")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.product,
      expression: "form.product"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "product_pricing",
      "id": "product_pricing"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "product", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.products, function (product, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": product.name
      }
    }, [_vm._v(" " + _vm._s(product.name) + " ")]);
  }), 0)])]), _vm.form.product == 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav3")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.price,
      expression: "form.price"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "price", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("description")))]), _c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.description,
      expression: "form.description"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "description", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.form.product == 'Wasiat' && _vm.organizationCode == 'ISYNERGY' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v("SKU ID")]), _c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.isynergy_sku_id,
      expression: "form.isynergy_sku_id"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.isynergy_sku_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "isynergy_sku_id", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("offer-sku")))]), _c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.isynergy_offer_sku_id,
      expression: "form.isynergy_offer_sku_id"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.isynergy_offer_sku_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "isynergy_offer_sku_id", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.form.product == 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.awaris-share")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.awaris_share,
      expression: "form.awaris_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.awaris_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "awaris_share", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_vm.organizationType == 'Awaris' ? _c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.partner-share")))]) : _vm._e(), _vm.organizationType != 'Awaris' ? _c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("business-partner")))]) : _vm._e(), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(2), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.organization_share,
      expression: "form.organization_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.organization_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "organization_share", $event.target.value);
      }
    }
  })])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.possb-share")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(3), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.prooffice_share,
      expression: "form.prooffice_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.prooffice_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "prooffice_share", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("other-sharing")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(4), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.others_share,
      expression: "form.others_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.others_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "others_share", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("other-sharing")) + " (" + _vm._s(_vm.$t("description")) + ")")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.others_description,
      expression: "form.others_description"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.others_description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "others_description", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.form.product == 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment-price")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(5), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_price,
      expression: "form.amendment_price"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_price", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.form.product == 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-awaris-amendments")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(6), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_awaris_share,
      expression: "form.amendment_awaris_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_awaris_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_awaris_share", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-business-partner-amendments")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(7), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_organization_share,
      expression: "form.amendment_organization_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_organization_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_organization_share", $event.target.value);
      }
    }
  })])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-possb-amendments")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(8), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_prooffice_share,
      expression: "form.amendment_prooffice_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_prooffice_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_prooffice_share", $event.target.value);
      }
    }
  })])]) : _vm._e()]) : _vm._e(), _vm.form.product == 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])])])]) : _vm._e(), _c('hr'), _vm.form.product != 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav3")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(9), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.price,
      expression: "form.price"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "price", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.form.product != 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.awaris-share")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(10), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.awaris_share,
      expression: "form.awaris_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.awaris_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "awaris_share", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.partner-share")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(11), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.organization_share,
      expression: "form.organization_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.organization_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "organization_share", $event.target.value);
      }
    }
  })])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("pricing.possb-share")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(12), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.prooffice_share,
      expression: "form.prooffice_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.prooffice_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "prooffice_share", $event.target.value);
      }
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("other-sharing")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(13), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.others_share,
      expression: "form.others_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.others_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "others_share", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("other-sharing")) + " (" + _vm._s(_vm.$t("description")) + ")")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.others_description,
      expression: "form.others_description"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.others_description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "others_description", $event.target.value);
      }
    }
  })])]) : _vm._e(), _vm.form.product != 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("amendment-price")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(14), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_price,
      expression: "form.amendment_price"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_price", $event.target.value);
      }
    }
  })])])]) : _vm._e(), _vm.form.product != 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-awaris-amendments")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(15), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_awaris_share,
      expression: "form.amendment_awaris_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_awaris_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_awaris_share", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-business-partner-amendments")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(16), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_organization_share,
      expression: "form.amendment_organization_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_organization_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_organization_share", $event.target.value);
      }
    }
  })])]), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("sharing-possb-amendments")))]), _c('div', {
    staticClass: "input-group"
  }, [_vm._m(17), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.amendment_prooffice_share,
      expression: "form.amendment_prooffice_share"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.amendment_prooffice_share
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "amendment_prooffice_share", $event.target.value);
      }
    }
  })])]) : _vm._e()]) : _vm._e(), _vm.form.product != 'Wasiat' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.status,
      expression: "form.status"
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])])])]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.savePricing
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(_vm._s(_vm.$t("save")) + " ")])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon1"
    }
  }, [_vm._v("RM")])]);
}]

export { render, staticRenderFns }